<template>
  <div :style="{height: display ? 'auto' : '200vh'}" :class="[isMobile ? 'mobile-about-us' : '', 'about-us', pageName]">    
    <template v-show="display">
    <common-header class="header" navName="aboutUs" :show="display" />
    <div :class="['page-wraper', pageName]">
      <div :style="{height: display ? 'auto' : '200vh'}" :class="['main-box', pageName]">
        <about-side-bar v-if="!isMobile" @pageName="getPageName" @loading="displayShow" />
        <keep-alive>
        <router-view @loading="displayShow" class="content"></router-view>
        </keep-alive>
      </div>
    </div>
    <common-footer v-show="display" />
    </template>
    <Service />
    <Loading v-show="!display" />
  </div>
</template>
<script>
import CommonHeader from '@/components/common/Header.vue'
import CommonFooter from '@/components/common/Footer.vue'
import Loading from '@/components/common/Loading.vue'
import Service from '@/components/common/Service.vue'

import AboutSideBar from '@/components/aboutUs/sideBar.vue'
import { isMobile } from '@/helper/utils'
export default {
  components: {
    CommonHeader,
    CommonFooter,
    AboutSideBar,
    Loading,
    Service
  },
  data () {
    return {
      isMobile: isMobile(),
      display: false,
      pageName: ''
    }
  },
  beforeCreate () {
    document.body.scrollTop=document.documentElement.scrollTop=0
    if (isMobile()) {
      document.querySelector('html').style.fontSize = '71px'
    }
  },
  methods: {
    displayShow (loading) {
      this.display = !loading
      var Element = document.querySelector('.mobile-header .head')
      if (Element) {
        var headerHeight = Element.offsetHeight
        document.getElementById('app').style.paddingTop = headerHeight + 'px'
      }
    },
    // 获取当前页面名称
    getPageName (pageName) {
      this.pageName = pageName
    }
  }
}
</script>
<style lang="scss">

</style>
<style lang="scss" scoped>
.about-us {  
  &.Introduction {    
    // background-image: url(../../assets/img/aboutus/introductionbg.jpg), url(../../assets/img/aboutus/introductionbg_bottom.png);
    // background-size: 100% auto, 100% auto;
    // background-repeat: no-repeat, no-repeat;
    // background-position: center top, center 47%;
    background-image: url(../../assets/img/aboutus/intro_full_bg.png);
    background-position: center top;
    background-size: 100% auto;
    
    // background-color: #fafcfc;
  }  
}
.page-wraper {
  padding-bottom: 1rem;
   &.Culture {
    background-image: url(../../assets/img/aboutus/culture_bg.png);
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: left bottom;
    padding-bottom: 4.5rem;
  }
}
.header {
  display: block!important;
}
.mobile-about-us {
  .page-wraper {
    padding-bottom: 30px;
  }
  padding-bottom: 0;
  .main-box {
    max-width: 100%;
    box-sizing: border-box;
    padding: 0 15px 0;
    padding-top: 0;
  }
}
.main-box {
  max-width: 14rem;
  margin: 0 auto 0;
  padding-top: 1.26rem;
  display: flex;
  .content {
    flex:1;
    overflow-x: hidden;
    overflow-y: hidden;
  }
}

</style>
