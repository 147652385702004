<template>
  <div class="about-side-bar">
    <div class="title">
      关于<br />明亚
    </div>
    <div class="side-nav">
      <div v-for="(item, index) in navData" :key="index" @click="switchNav(item.class, index)" :class="[currentNav === item.class || (item.activeClass && item.activeClass.includes(currentNav)) ? 'active' : '', index + 1 === currentIndex ? 'activePrev' : '', 'nav-item']">
        <div>{{item.name}}</div>
        <img v-if="item.class == currentNav" src="../../assets/img/aboutus/more_active.png" alt="">
        <img v-else src="../../assets/img/aboutus/more.png" alt="">
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      currentNav: 'Introduction',
      currentIndex: 0,
      navData: [
        { name: '明亚简介', class: 'Introduction' },
        { name: '明亚文化', class: 'Culture' },
        { name: '明亚荣誉', class: 'Honor' },
        { name: '社会责任', class: 'responsibility' },
        { name: '新闻动态', class: 'news' },
        { name: '媒体聚焦', class: 'media' },
        { name: '明亚年报', class: 'report', activeClass: ['report', 'reportPdf'] },
      ]
    }
  },
  created () {
    if (this.$route.name == 'Detail') {
      this.currentNav = this.$route.query.type

    } else {
      this.currentNav = this.$route.name
    }
    let _this = this
    this.navData.forEach((item, index) => {
      if (item.class == _this.currentNav) {
        _this.currentIndex = index
      }
    }) 
    this.$emit('pageName', this.currentNav)
  },
  watch:{
    $route(to){
      if (to.name != 'Detail') {
        this.currentNav = to.name
      }
      
    }
  },
  methods: {
    // 导航切换
    switchNav (nav,index) {
      // console.log(this.$route)
      this.currentIndex = index
      document.body.scrollTop=document.documentElement.scrollTop=0
      // if (this.currentNav == nav) {
      //   return false
      // }
      this.currentNav = nav
      if (this.currentNav != nav) {
        this.$emit('loading', true)
      }
      
      if (this.$route.name != nav) {
        this.$router.push({path: '/aboutUs/' + nav })
      }      
      this.$emit('pageName', nav)
    }
  }
}
</script>
<style lang="scss" scoped>
.about-side-bar {
  // position: fixed;
  // top: 5rem;
  width: 3rem;
  height: auto;
  
  .title {
    width: 100%;
    height: 1.1rem;
    background-image: url(../../assets/img/aboutus/nav.png);
    background-size: 100% 100%;
    font-size: 0.3rem;
    color: #ffffff;
    box-sizing: border-box;
    padding: .19rem .24rem 0;
    line-height: 1.3;
  }
  .side-nav {
    width: 100%;
    .nav-item {
      background-color: #ffffff;
      width: 100%;
      display: flex;
      height: .55rem;
      justify-content: space-between;
      align-items: center;
      font-size: .18rem;
      color: #333333;
      box-sizing: border-box;
      padding: 0 0.2rem;
      border-bottom: 1px solid #DCDCDC;
      &.activePrev {
        border-bottom: 1px solid rgba(255, 168, 41, 0);
      }
      cursor: pointer;
      &:hover {
        background-color: #FFF8EC;
        color: #FF6004;
        border:1px solid rgba(255, 168, 41, .1);
      }
      &.active {
        background-color: #FFF8EC;
        color: #FF6004;
        border-color: rgba(255, 168, 41, .1);
        position: relative;
        &::after {
          display: block;
          content: "";
          height: 100%;
          width: 0.04rem;
          background-color: #FF6004;
          position: absolute;
          left: 0;
          top: 0;
        }
      }
    }
    img {
      width: 0.06rem;
      height: 0.1rem;
    }
  }
}
</style>